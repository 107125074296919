import { Component,EventEmitter,Input, Output } from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent {
@Input() radioText:string;
@Input() radioClass:string;
@Output() changeEvent: EventEmitter<any> = new EventEmitter();

  change(event: any) {
    this.changeEvent.emit(event); 
  }
}
