<div *ngIf="!isGMSign" class="accountLogin__bodySection"
  [ngClass]="{'sdex-env': envType == appType.dams || envType == appType.clms}">
  <div class="accountLogin__body" [ngClass]="{'sdex-env-dams_box': envType == appType.dams || envType == appType.clms}">
    <div class="accountLogin__section"
      [ngClass]="{'sdex-env-dams': envType == appType.dams || envType == appType.clms}">
      <div ngClass="{{ envType!=='SDEX' ? 'accountLogin__logo_url' : 'accountLogin__logo'}}">
        <div *ngIf="envType=='SDEX'">
          <img src="../../../assets/Images/login/headerLogo.svg" alt="">
          <img src="../../../assets/Images/login/sdexLogo.svg" alt="sdex">
        </div>
        <div ngClass="{{customUrldata?.logo?.length ? 'accountLogin__logo_url' : 'accountLogin__logo'}}">
          <img ngClass="{{customUrldata?.logo?.length ? 'account_login_customlogo_url' : 'account_login_customlogo'}}"
            *ngIf="envType!=='SDEX' && customUrldata?.logo?.length" [src]="customUrldata.logo" alt="sdex">
          <div>
            <img ngClass="{{customUrldata?.logo?.length ? 'account_login_customlogo_url' : 'account_login_customlogo'}}"
              *ngIf="envType!=='SDEX' &&(!customUrldata?.logo?.length)"
              [src]="'../../../assets/Images/login/headerLogo.svg'" alt="sdex">
            <img *ngIf="envType!=='SDEX'" src="../../../assets/Images/login/sdexLogo.svg" alt="sdex">
          </div>
          <h3 *ngIf="envType !=='SDEX' && customUrldata?.companyName" class="accountLogin__logo_dams">
            {{customUrldata?.companyName.toUpperCase()}} </h3>
        </div>
      </div>
      <form name="loginForm" [formGroup]="loginForm">
        <div class="accountLogin__signIn">
          <div class="accountLogin__welcome">Welcome to</div>
          <div *ngIf="envType == appType.sdex" class="accountLogin__welcomeHeader">Secured Document Exchange</div>
          <div *ngIf="envType == appType.dams" class="accountLogin__welcomeHeader_dams">Intelligent Document Processing
          </div>
          <div *ngIf="envType == appType.clms" class="accountLogin__welcomeHeader_dams">Contract Management System</div>
          <div class="accountLogin__inputWrapper" *ngIf="totpPendingId">
            <div class="accountLogin__inputSection">
              <div class="userManagementUsersList__user-management-section">
                <section class="organisationalDetails__container">
                  <div class="organisationalDetails__details">
                    <p class="userManagementUsersList__user-management-section1">Additional verification required</p>
                    <p>Username: {{ loginForm.get('username')?.value || '' }} <span
                        class="cursor-pointer link-underline link-primary" (click)="this.totpPendingId = undefined">(Not
                        you?)</span></p>
                    <br>
                    <p>Please enter the six digit code from your authenticator app.</p>
                    <label>MFA code </label>
                    <app-input-filed type="number" maxlength="6"
                      [control]="$any(loginForm).controls['totp']"></app-input-filed>
                    <div class="mfa-sign">
                      <app-common-button buttonClass="smallFilledButton" [buttonLabel]="'Sign In'"
                        custButtonClass="custButtonClass" [loadingIcon]="signInLoader" [typeBtn]="true"
                        (clickEvent)="verifyTotp()">
                      </app-common-button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div class="accountLogin__inputWrapper" *ngIf="!totpPendingId">
            <div class="accountLogin__inputSection">
              <div class="accountLogin__inputLabel">Email</div>
              <app-input-filed (inputEvent)="inputEvent($event)" [control]="$any(loginForm).controls['username']"
                placeholder="Email"> </app-input-filed>
              <div class="errorText"
                *ngIf="loginForm.controls['username'].invalid && (loginForm.controls?.['username']?.dirty || loginForm.controls['username'].touched)">
                <div *ngIf="loginForm.controls['username'].errors?.['pattern']">
                  <small class="text-danger">Please enter valid email</small>
                </div>
                <div *ngIf="loginForm.controls['username'].errors?.['required']">
                  <small class="text-danger">Email is required</small>
              </div>
              </div>
            </div>          
            <div class="accountLogin__inputSection">
              <div class="accountLogin__inputLabel"> Password</div>
              <div class="usersFilter__branchesField" [ngClass]="{'sdex-env-input': envType == 'DAMS'}">
                <div class="usersFilter__branchWrappar">
                  <input (input)="inputEvent($event)" [type]="'password'" id="password"
                    class="usersFilter__input overrideInputfield" style="
              border: none !important;
              outline: none !important;
              box-shadow: none !important;
              border-color: #000 !important;
              width: 320px;
            " oncopy="return false" onpaste=" return false" type="{{ hide ? 'password' : 'text' }}"
                    formControlName="password" placeholder="Password" />
                  <fa-icon class="usersFilter__searchIcon" [icon]="hide ? faEye : faEyeSlash" (click)="hide = !hide"
                    [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide"></fa-icon>
                </div>
              </div>
              <div class="errorText"
                *ngIf="loginForm.controls['password'].invalid && (loginForm.controls?.['password']?.dirty || loginForm.controls['password'].touched)">
                <div *ngIf="loginForm.controls['password'].errors?.['required']">
                  <small class="text-danger">Password is required</small>
                </div>
              </div>
            </div>
          </div>

          <div class="accountLogin__forgotPassword" *ngIf="!totpPendingId">
            <div class="accountLogin__remember">

              <a style="text-decoration: none;" [routerLink]="['/setup-account']" class="text-link">
                <div class="accountLogin__rememberText">Setup Password</div>
              </a>
            </div>
            <a style="text-decoration: none;" [routerLink]="['/forgot-password']">
              <div class="accountLogin__passwordText">Forgot Password ?</div>
            </a>
          </div>
          <div class="accountLogin__button" *ngIf="!totpPendingId">
            <app-common-button (clickEvent)="onLogin()" buttonClass="smallFilledButton" [buttonLabel]="'Sign In'"
              custButtonClass="custButtonClass" disableButtonClass="largeButtonDisable" [loadingIcon]="signInLoader" [typeBtn]="true" [disabled]="loginForm.invalid || signInLoader">
            </app-common-button>
          </div>
          <div [ngClass]="{'mfa-sso': totpPendingId}" class="btn-sso">
            <app-common-button [loadingIcon]="isGoogleSign" (clickEvent)="googleSignIn('google')"
              [isImg]="'../../../assets/Images/login/google.png'" buttonClass="ssoButton"
              [buttonLabel]="'Sign In with Google'">
            </app-common-button>
            <app-common-button [loadingIcon]="isMicrosoftSign" (clickEvent)="googleSignIn('microsoft')"
              [isImg]="'../../../assets/Images/login/microsoft.png'" buttonClass="ssoButton"
              [buttonLabel]="'Sign In with Microsoft'">
            </app-common-button>
          </div>
          <div class="mt-2 text-center text-danger pb-3 error-msg-wd">
            {{errorLogin}}
            <span *ngIf="isChangePassword">
              <div class="accountLogin__passwordText">Click
                <span><a href="javascript:;" style="color: rgb(222 113 65);" (click)="changePassword()">here</a></span>
                to change password
              </div>
            </span>
          </div>
        </div>
      </form>
    </div>
    <app-logo *ngIf="envType=='SDEX'"></app-logo>
  </div>
</div>