<table class="custom-table-list" aria-label="users">
  <thead class="custom-table-list-headings">
    <th class="custom-col-width-10">Status</th>
    <th class="custom-col-width-15 ps-4">Name</th>
    <th class="custom-col-width-15">Email ID</th>
    <th class="custom-col-width-15 ps-4">Mobile No.</th>
    <th class="custom-col-width-20 ps-3" *ngIf="!isAdmin">Branches</th>
    <th class="custom-col-width-15">Roles</th>
    <th appAccessControl allowedModules="documentVault.scanner" *ngIf="!isAdmin && selfDetails?.primary && envType == appType.dams"
      class="custom-col-width-10">Licence Key</th>
    </thead>

  <tr class="custom-table-list-row-main cursor-pointer" *ngFor="let tableData of userData">
    <td class="custom-col-width-10 userTable__status clickable-td" (click)="editUser('status', tableData)">
      <div *ngIf="tableData.status === 'New'">
        <img src="../../../../assets/Images/user-management/user-invited.svg" alt="invited" />
      </div>
      <div *ngIf="tableData.status === 'Locked'">
        <img src="../../../../assets/Images/user-management/user-unverified.svg" alt="unverified" />
      </div>
      <div *ngIf="tableData.status === 'Active'">
        <img src="../../../../assets/Images/user-management/user-verified.svg" alt="verified" />
      </div>
      <div [ngClass]="{
        userTable__statusTextBlue: tableData.status === 'New',
        userTable__statusTextOrange: tableData.status === 'Locked',
        userTable__statusTextGreen: tableData.status === 'Active'
      }">
        {{ tableData.status }}
      </div>
      <p *ngIf="tableData.primary" class="badge badge-primary badge-lt-40">
        Primary
      </p>
    </td>
    <td class="custom-col-width-15 clickable-td ps-4" (click)="editUser('name', tableData)">
      <span class="documentRowCards__container--text" [ngbTooltip]="getTooltipName(tableData?.name)"
        [placement]="'top-start'" [tooltipClass]="'custom-tooltip'">
        {{tableData?.name}}
      </span>
    </td>

    <td class="custom-col-width-15 clickable-td" (click)="editUser('email', tableData)">
      <span class="documentRowCards__container--text" [ngbTooltip]="getTooltipEamilName(tableData?.emailAddress)"
        [placement]="'top-start'" [tooltipClass]="'custom-tooltip'">
        {{tableData?.emailAddress}}
      </span>
    </td>

     <td class="custom-col-width-15 ps-4 clickable-td" (click)="editUser('mobileNumber', tableData)">
      <span *ngIf="tableData?.mobileNumber?.length < maxFilelength" class="documentRowCards__container--text" [ngbTooltip]="tableData?.mobileNumber" [placement]="'top-start'" [tooltipClass]="'custom-tooltip'">
      {{ tableData?.mobileNumber }}</span>
      <span *ngIf="tableData?.mobileNumber?.length >= maxFilelength" class="documentRowCards__container--text" [ngbTooltip]="tableData?.mobileNumber" [placement]="'top-start'" [tooltipClass]="'custom-tooltip'">
        {{largeFileName(tableData?.mobileNumber)}}...
      </span>
     </td>

    <td class="custom-col-width-20 ps-3 clickable-td" *ngIf="!isAdmin">
    <span (click)="!tableData?.company?addBranch(tableData): editUser('company', tableData)" class="documentRowCards__container--text" (mouseover)="openPanel('Company',tableData, $event)"
    (mouseout)="closePanael('Company')">
      {{ tableData?.company }}
    </span>
    <p-overlayPanel #Company>
      <ng-template pTemplate="content">
        <div class="roles_background">
          <ul>
            <li *ngFor="let company of companyData; index as i">
              <div class="role_text">{{ company }}</div>
              <hr class="hr_spacing" *ngIf="i != companyData.length - 1">
            </li>
          </ul>
        </div>
      </ng-template>
    </p-overlayPanel>
    <div appAccessControl allRoles="company.view,user.edit">
      <a *ngIf="!tableData?.company" (click)="addBranch(tableData)">Add Branch</a>
    </div>
    </td>
  <td class="custom-col-width-15 clickable-td">
    <span (click)="!tableData?.roles?addBranch(tableData):editUser('roles', tableData)" class="documentRowCards__container--text" (mouseover)="openPanel('Branch',tableData, $event)"
      (mouseout)="closePanael('Branch')">
      {{(tableData?.primary && tableData?.enrollmentType === 'Admin') ? 'Super Admin' :  tableData?.roles }}</span>
    <p-overlayPanel #Branch>
      <ng-template pTemplate="content">
        <div class="roles_background">
          <ul>
            <li *ngFor="let role of userRoleData; index as i">
              <div class="role_text">{{ role }}</div>
              <hr class="hr_spacing" *ngIf="i != userRoleData.length - 1">
            </li>
          </ul>
        </div>
      </ng-template>
    </p-overlayPanel>
      <a appAccessControl allRoles="user.edit,role.view" *ngIf="!tableData?.roles && !isAdmin" (click)="addBranch(tableData)">Add Role</a>
  </td>
  <td *ngIf="!isAdmin && selfDetails?.primary && envType == appType.dams" appAccessControl
    allowedModules="documentVault.scanner" class="custom-col-width-5">
    <div class="text-secondary text-center">
      <div class="icon-key">
        <app-common-icon class="key-color" placement="top" toolTip="Assign scaner licence key"
          (clickEvent)="assignScanLicence(tableData)" [faIcon]="faKey"></app-common-icon>
        <fa-icon class="check-icon" *ngIf="tableData?.licenseId" [icon]="faCircleCheck"></fa-icon>
      </div>
    </div>
  </td>

  <td class="custom-col-width-5 clickable-td">
  <p-overlayPanel #op>
    <ng-template pTemplate="content">

    </ng-template>
  </p-overlayPanel>
</td>
</tr>
<div class="text-danger d-flex justify-content-center" *ngIf="userData?.length === 0">
  <h4>No records found</h4>
</div>

</table>


<ng-template tabindex="-1" #selecteLicence role="dialog" aria-labelledby="selecteLicence" aria-hidden="true"
  let-selecteLicence>
  <div class="modal-header zindex-modal">
    <h4 class="modal-title" id="modal-basic-title">
      Assign Scanner Licence
    </h4>
    <div (click)="selecteLicence.dismiss(false)"  class="close">
      <img src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon">
    </div>
  </div>
  <div class="modal-body">
    <div class="row mt-2">
      <div class="col">
        <div class="form-group">
          <div class="dropdown">
            <div class="dropdownLabel">Licence</div>
            <select #licence (change)="onChangeLicence(licence.value)" class="dropdownOptions drop-down">
              <option value="" disabled selected>Select Licences</option>
              <option *ngFor="let item of companyLicences" [value]="item._id">
                <!-- <span class="test">{{item.value}}</span> -->
                {{ item.value.substring(0, 45) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="text-right">
      <div class="d-flex justify-content-end">
        <app-common-button class="m-1" buttonClass="smallbutton" buttonLabel="Cancel"
          (clickEvent)="selecteLicence.dismiss(false)"></app-common-button>
        <app-common-button [loadingIcon]="loderIcon" [disabled]="!selectedLicence" (clickEvent)="selecteLicence.close(true)"
          class="m-1" buttonClass="smallFilledButton" buttonLabel="Submit"></app-common-button>
      </div>
    </div>
  </div>
</ng-template>

<app-toast *ngIf="isToaster" [title]="message" (closeToaster)="closeToaster($event)"></app-toast>

<app-error-toaster *ngIf="isErrorToaster" [title]="message" (closeToaster)="closeToaster($event)"></app-error-toaster>