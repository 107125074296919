<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.1/font/bootstrap-icons.css" />
<div class="createAccount__bodySection" [ngClass]="{'sdex-env': envType == appType.dams || envType == appType.clms}">
<div class="createAccount__body" [ngClass]="{'sdex-env-dams_box': envType == appType.dams || envType == appType.clms}">
  <div class="createAccount__section" [ngClass]="{'sdex-env-dams': envType == appType.dams || envType == appType.clms}">
    <div  ngClass="{{(customUrldata?.logo?.length && envType!=='SDEX') ? 'accountLogin__logo_url' : envType=='SDEX'?'accountLogin__logo':'accountLogin__logo_dc'}}">
    <div class="accountLogin__logo" *ngIf="envType=='SDEX' ">
      <img src="../../../assets/Images/login/headerLogo.svg" alt="sdex">
      <img src="../../../assets/Images/login/sdexLogo.svg" alt="sdex">
    </div>
    <div ngClass="{{customUrldata?.logo?.length ? 'accountLogin__logo_url' : envType=='SDEX'?'accountLogin__logo':'accountLogin__logo_dc'}}">
      <img ngClass="{{customUrldata?.logo?.length ? 'account_login_customlogo_url' : 'account_login_customlogo'}}"
        *ngIf="envType!=='SDEX' && customUrldata?.logo?.length" [src]="customUrldata.logo" alt="sdex">
       <div>
        <img ngClass="{{customUrldata?.logo?.length ? 'account_login_customlogo_url' : 'account_login_customlogo'}}"
            *ngIf="envType!=='SDEX' &&(!customUrldata?.logo?.length)" [src]="'../../../assets/Images/login/headerLogo.svg'" alt="sdex">
            <img *ngIf="envType!=='SDEX'" src="../../../assets/Images/login/sdexLogo.svg" alt="sdex">
            </div>
      <h3 *ngIf="envType !=='SDEX' && customUrldata?.companyName" class="accountLogin__logo_dams">
        {{customUrldata?.companyName.toUpperCase()}} </h3>
    </div>
    </div>
    <form *ngIf="showForm1" name="forgotpwdForm" [formGroup]="forgotpwdForm">
      <div class="createAccount__signIn">
        <div class="createAccount__setup">{{isChangePassword?'Change':'Forgot'}} Password</div>
        <div class="createAccount__addCredintials">
          Add your registered email to receive an OTP
        </div>
        <div class="createAccount__inputWrapper">
          <div class="createAccount__inputSection">
            <div class="createAccount__inputLabel">
              Enter a registered email
            </div>
            <app-input-filed [control]="$any(forgotpwdForm).controls['emailAddress']" required placeholder="Email"
              (inputEvent)="getInputValue($event)">
            </app-input-filed>
            <div class="text-danger" *ngIf="forgotpwdForm.controls['emailAddress'].invalid && 
                        (forgotpwdForm.controls?.['emailAddress']?.dirty || 
                        forgotpwdForm.controls['emailAddress'].touched)">
              <div *ngIf="forgotpwdForm.controls['emailAddress'].errors?.['pattern']">
                Please enter a valid email address
              </div>
              <div *ngIf="forgotpwdForm.controls['emailAddress'].errors?.['required']">
                Email is required
              </div>
            </div>
            <div *ngIf="errorMessage" class="text-center text-danger pt-2 error-msg-wd">
              {{ errorMessage }}
            </div>
          </div>
        </div>
        <div class="createAccount__error">
          <img src="../../../assets/Images/login/error.svg"  alt="error"/>
          <div class="createAccount__errorText">
            You will receive a mail with an OTP to verify your account
          </div>
        </div>
        <div class="createAccount__button">
          <app-common-button buttonClass="smallFilledButton" [buttonLabel]="'Verify Email'"
          (click)="onForgotpwd()" custButtonClass="custButtonClass" disableButtonClass="largeButtonDisable" [loadingIcon]="forgotPwdLoader"
          [typeBtn]="true" [disabled]="forgotpwdForm.invalid || forgotPwdLoader">
          </app-common-button>
        </div>
      </div>

      <div class="accountLogin__signUp">
        <div class="accountLogin__signupText">Already have an account?</div>
        <a style="text-decoration: none" [routerLink]="['/home']">
          <div class="accountLogin__passwordText">Sign In</div>
        </a>
      </div>

    </form>
    <form *ngIf="showForm2" #form2="ngForm" name="registerForm2" [formGroup]="registerForm2">
      <div class="createAccount__signIn">
        <div class="createAccount__setup">{{isChangePassword?'Change':'Forgot'}} Password</div>

        <div class="createAccount__inputWrapper">
          <div class="createAccount__inputSection">
            <div class="createAccount__inputLabel">Enter an OTP</div>

            <div class="createAccount__inputWrapper">
              <input class="createAccount__input" placeholder="******" formControlName="otp" id="otp" type="text"
              (input)="validateOTP($event)" maxlength="6" required />
              <button [ngClass]="disabled ?'disableBtn':'createAccount__verifyButton'" [disabled]="disabled"
                (click)="resendOtp()">Resend OTP</button>
            </div>
            <div *ngIf="
                !registerForm2.controls['otp'].valid &&
                registerForm2.get('otp')?.touched
              ">
              <small class="text-danger">OTP is required</small>
            </div>
          </div>
          <div class="createAccount__inputSection">
            <div class="createAccount__inputLabel">Create a password</div>
            <div class="usersFilter__branchesField">
              <div class="usersFilter__branchWrappar">
                <input class="usersFilter__input" style="
                    border: none;
                    outline: none !important;
                    box-shadow: none !important;
                    border-color: #000 !important;
                    width: 336px;
                  "
                  oncopy = "return false"
                  onpaste =" return false"
                  placeholder="***********" formControlName="password" class="" id="password"
                  type="{{ hide2 ? 'password' : 'text' }}" required minlength="8" maxlength="15" />

                <fa-icon class="usersFilter__searchIcon" [icon]="hide2 ? faEye : faEyeSlash" (click)="hide2 = !hide2"
                  [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide"></fa-icon>
              </div>

              <div class="invalid-feedback" *ngIf="
                  !registerForm2.controls['password']?.valid &&
                  registerForm2.controls['password']?.touched
                ">
                <small class="text-danger">Password is required</small>
              </div>
            </div>
            <div *ngIf="registerForm2.get('password')?.hasError('pattern')" style="max-width: 400px">
              <img src="../../../assets/Images/login/error.svg" alt="error"/>

              <small class="text-muted">Hint- Minimum of 8 characters with a uppercase, lowercase,
                special character and a number required.</small>

            </div>

          </div>

          <div class="createAccount__inputSection" style="margin-bottom: 30px;">
            <div class="createAccount__inputLabel">Confirm password</div>
            <div class="usersFilter__branchesField">
              <div class="usersFilter__branchWrappar">
                <input class="usersFilter__input" style="
                    border: none;
                    outline: none !important;
                    box-shadow: none !important;
                    border-color: #000 !important;
                    width: 336px;
                  "
                  oncopy = "return false"
                  onpaste =" return false"
                  placeholder="***********" formControlName="repassword" class="" id="password"
                  type="{{ hide ? 'password' : 'text' }}" required minlength="8" maxlength="15" />


                <fa-icon class="usersFilter__searchIcon" [icon]="hide ? faEye : faEyeSlash" (click)="hide = !hide"
                  [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide"></fa-icon>
              </div>
            </div>
            <div style="color: red" *ngIf="
                registerForm2.get('password')?.value !==
                  registerForm2.get('repassword')?.value &&
                registerForm2.get('repassword')?.touched
              ">
              Passwords do not match.
            </div>
          </div>
        </div>

      </div>
      <div *ngIf="showErrorMessage.show" class="d-flex justify-content-center">
        <p class="color-red">{{ showErrorMessage?.message }}</p>
      </div>
      <div class="createAccount__button">
        <app-common-button (clickEvent)="onVerify2()" [loadingIcon]="forgotPwdLoader"  [disabled]="registerForm2.invalid" buttonClass="createAccount__signInButton"
                           buttonLabel="Submit" disableButtonClass="createAccount__signInButtonDisable"></app-common-button>
      </div>
      <div class="accountLogin__signUp p-3">
        <div class="accountLogin__signupText">Back to</div>
        <div class="accountLogin__passwordText" [routerLink]="['/home']">
          Sign In
        </div>
      </div>
    </form>
    <form *ngIf="showForm3" name="congratsForm" class="p-3">
      <div>
        <div class="d-flex flex-column align-items-center text-center">

          <i class="fa-solid fa-check"></i>
          <h2>Congratulations!</h2>
          <p>Your password reset process is completed.</p>
          <app-common-button [routerLink]="['/home']" buttonClass="smallbutton text-center"
            buttonLabel="Login"></app-common-button>
        </div>
      </div>

      <div class="">

      </div>
    </form>
  </div>

  <app-logo *ngIf="envType=='SDEX' "></app-logo>

</div>
</div>
