export enum StorageKeys {
  ACCESS_TOKEN = 'token',
  ROLE = 'role',
  USER_ID = 'userId',
  COMPANY_ID = 'companyId',
  SELF_DETAILS = 'selfDetails',
  PRESENT_COMPANY = 'presentCompany',
  PREFERENCES = 'preferences',
  SCANER_LICENCE = 'scanerLicence'
}
