
  <div class="modal-header zindex-modal">
    <h4 class="modal-title" id="modal-basic-title">
      Assign Scanner Licence
    </h4>
    <div (click)="activeModal.dismiss(false)"  class="close">
      <img src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon">
    </div>
  </div>
  <div class="modal-body">
    <div *ngIf="companyLicences?.length == 0">
     <small class="text-danger"> Empty scanner Licence</small>
    </div>
    <table class="custom-table-list" aria-label="users">
        <tr class="custom-table-list-row-main cursor-pointer" *ngFor="let item of companyLicences; index as i">
            <td data-bs-toggle="tooltip" [tooltipClass]="'info-tooltip'" [ngbTooltip]="item.value" placement="top"> {{item.value}}</td>
            <td class="radio-btn"> 
                <app-radio-button (changeEvent)="onSelectLicence(item)"></app-radio-button>
             </td>
        </tr>
    </table>
  </div>
  <div class="modal-footer">
    <div class="text-right">
      <div class="d-flex justify-content-end">
        <app-common-button class="m-1" buttonClass="smallbutton" buttonLabel="Cancel"
          (clickEvent)="activeModal.dismiss(false)"></app-common-button>
        <app-common-button [disabled]="!selectedLicenceid"  (clickEvent)="onSubmit()"
          class="m-1" buttonClass="smallFilledButton" buttonLabel="Submit"></app-common-button>
      </div>
    </div>
  </div>
