import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-licence-list',
  templateUrl: './licence-list.component.html',
  styleUrl: './licence-list.component.scss'
})
export class LicenceListComponent implements OnInit {
@Input() companyLicences: any
selectedLicenceid: any;
  constructor(public activeModal: NgbActiveModal){

  }

  ngOnInit(): void {
    
  }

  onSelectLicence(value: any){
    this.selectedLicenceid = value?._id;
  }

  onSubmit(){
    this.activeModal.close({
      isSubmit: true,
      id:this.selectedLicenceid
    })
  }
}
