import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { environment } from 'src/environments/environment';
import { StorageKeys } from './share/enums/storage.enum';
import { APPTYPES } from './share/constants/common-constants';

@Directive({
  selector: '[appAccessControl]',
})
export class AccessControlDirective implements OnInit {
  @Input('allowedRoles') allowedRoles: string;
  @Input('allowedModules') allowedModules : string;
  @Input('allRoles') allRoles: string;
  @Input('restrictedRoles') restrictedRoles: string;
  @Input('restrictedModules') restrictedModules: string;
  @Input('showingCards') showingCards: any[];
  @Input('cardTitle') cardTitle: string;
  @Input('skipAccess') skipAccess: any = false;
  @Input('showCard') showCard: boolean = false;
  companyPrefs: any;

  constructor(
    private elementRef: ElementRef,
    private storageService: StorageService
  ) {
    this.companyPrefs = this.storageService.getDataFromLocalStorage(StorageKeys.PREFERENCES)
  }

  ngOnInit() {
    if (!this.skipAccess || (this.skipAccess === 'false')) {
      this.elementRef.nativeElement.style.display = 'none';
      this.checkAccess();
    }
  }

  checkHasPermission(
    presentRoles: any,
    moduleType: string,
    accessType: string
  ) {
    let hasPermission = false;
    for (const presentRole of presentRoles || []) {
      for (const permission of presentRole.permissions || []) {
        if (permission.module === moduleType) {
          if (
            permission.subPermissions?.[accessType] ||
            permission.enabled ||
            (accessType === 'view' &&
              Object.keys(permission.subPermissions || {}).length)
          ) {
            hasPermission = true;
            break;
          }
        }
      }
    }
    return hasPermission;
  }

  checkHasRole(userRoles: string, selfDetails: any, shouldConsistsAll: boolean = false): boolean {
    let hasRole = false;
    let hasAllRoles = true;
    const allowedRoles = (userRoles || '').split(',');
    for (const role of allowedRoles) {
      const moduleType = role.split('.')[0];
      const accessType = role.split('.')[1];
      let hasPermission = this.checkHasPermission(
        selfDetails?.presentRoles || [],
        moduleType,
        accessType
      );
      hasRole = hasRole || hasPermission;
      if(!hasPermission){
        hasAllRoles = false;
      }
    }
    if(shouldConsistsAll){
      return hasRole ? hasAllRoles : false;
    }
    return hasRole;
  }

  checkModuleDisabled(
    allowedModules: any[string] = [],
    permissions: any
  ) {
    if (!allowedModules.length) {
      return false;
    }

    for (const role of allowedModules) {
      const moduleParts = role.split('.');
      let checkPermissions: any = permissions;
      for (const item of moduleParts) {
        if (checkPermissions === undefined) {
          break;
        }
        checkPermissions = checkPermissions[item];
      }

      if (checkPermissions) {
        return false;
      }
    }

    return true;
  }

  checkAccess() {
    const selfDetails = this.storageService.getDataFromLocalStorage(
      StorageKeys.SELF_DETAILS
    );

    let isAdmin: boolean = false;
    selfDetails?.assignedTo?.map((ele: any) => {
      isAdmin = ele.isAdmin;
    });
    const enrollmentType = selfDetails?.enrollmentType;
    let checkModule = this.allowedModules?.length;
    if (
      this.allowedModules?.length &&
      this.checkModuleDisabled(
        this.allowedModules?.split(','),
        this.companyPrefs
      ) && enrollmentType !== 'Admin'
    ) {
      return this.updateElementDisplay(false);
    }
    if (
      this.restrictedModules?.length &&
      !this.checkModuleDisabled(
        this.restrictedModules?.split(','),
        this.companyPrefs
      ) && enrollmentType !== 'Admin'
    ) {
      return this.updateElementDisplay(false);
    }
    let hasAccess =  enrollmentType === 'Admin' && (selfDetails?.primary || isAdmin);
    const isAllowedRole = this.checkHasRole(
      this.allRoles || this.allowedRoles || '',
      selfDetails,
      !!this.allRoles
    );  
    const isRestrictedRole = this.checkHasRole(
      this.restrictedRoles || '',
      selfDetails
    );
    if (isAllowedRole) {
      hasAccess = !isRestrictedRole;
    } else if (selfDetails?.primary || selfDetails?.isAdmin) {
      hasAccess = !(this.allowedRoles && this.restrictedRoles);
    }
      hasAccess = this.checkAppTypeforCLMS(selfDetails, hasAccess);
    
    this.updateElementDisplay(hasAccess);
  }

  checkAppTypeforCLMS(selfDetails: any, hasAccess: boolean){
    if(!this.companyPrefs['eSign']?.enabled){
      return hasAccess;
    }
    const hideActionsNoEsign = selfDetails?.hideActionsNoEsign;
    const skipNoActions = this.allowedRoles?.indexOf('skipNoActions') === -1;
    const view = this.allowedRoles?.indexOf('.view') === -1;
    const hideActionsNoBlockchain = selfDetails?.hideActionsNoBlockchain;

    if(hideActionsNoEsign && this.allowedRoles &&
      skipNoActions && this.allowedRoles.indexOf('e-sign-details.enabled') !== -1 ){
        return true;
    }

    if (hideActionsNoEsign && this.allowedRoles && view && skipNoActions) {
      return false;
    }

    if (hideActionsNoBlockchain && this.allowedRoles  && skipNoActions && view) {
      return false;
    }

    if (
      hideActionsNoEsign &&
      this.allowedRoles &&
      this.allowedRoles.indexOf('e-sign-details.enabled') !== -1
    ) {
      return true;
    }

    return hasAccess;
  }

  checkAppType(selfDetails: any, hasAccess: boolean) {
    const hideActionsNoEsign = selfDetails?.hideActionsNoEsign;
    const hideActionsNoBlockchain = selfDetails?.hideActionsNoBlockchain;
    const skipNoActions = this.allowedRoles?.indexOf('skipNoActions') === -1;
    const view = this.allowedRoles?.indexOf('.view') === -1;

    if (hideActionsNoEsign && this.allowedRoles && skipNoActions && view) {
      return false;
    }

    if (hideActionsNoBlockchain && this.allowedRoles  && skipNoActions && view) {
      return false;
    }
    if (
      hideActionsNoEsign &&
      this.allowedRoles &&
      this.allowedRoles.indexOf('e-sign-details.enabled') !== -1
    ) {
      return true;
    }
    if (
      selfDetails?.hideActionsNoBlockchain &&
      this.allowedRoles &&
      this.allowedRoles.indexOf('package.checker') !== -1 &&
      this.allowedRoles.indexOf('skipNoActions') !== -1
    ) {
      return true;
    }

    return hasAccess;
  }

  updateElementDisplay(hasAccess: boolean) {
    this.elementRef.nativeElement.style.setProperty('display', hasAccess ? 'flex' : 'none', 'important');
    if (
      hasAccess &&
      this.cardTitle &&
      this.showingCards &&
      this.showCard &&
      this.showingCards.indexOf(this.cardTitle) === -1
    ) {
      if (!this.showingCards.includes(this.cardTitle)) {
        this.showingCards.push(this.cardTitle);
      }
    }
  }
}
