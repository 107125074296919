import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faEye, faEyeSlash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import * as CryptoJS from 'crypto-js';
import { RegExpPatterns } from 'src/app/share/enums/regex-pattern.enum';
import { SetupAccountService } from 'src/app/share/services/setup-account.service';
import { environment } from 'src/environments/environment';
import { APPTYPES } from 'src/app/share/constants/common-constants';
import { AuthService } from '../auth/auth.service';
import { DOCUMENT } from '@angular/common';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';


@Component({
  selector: 'app-setup-account',
  templateUrl: './setup-account.component.html',
  styleUrls: ['./setup-account.component.scss']
})
export class SetupAccountComponent {
  showForm3             : boolean = false;
  showForm4             : boolean = true;
  showForm5             : boolean = false;
  hide                  : boolean = true;
  hide2                 : boolean = true;
  ifReg                 : boolean = true;
  disabled              : boolean = true;
  welRegHeader          : boolean = true;
  subRegHeader          : boolean = true;
  verifyLoader          : boolean = false;
  typeBtn               : boolean;
  companyNamevalue      : any;
  registrationTypevalue : any;
  emailAddressvalue     : any;
  namevalue             : any;
  secondaryUserEmail    : any;
  errorMsg              : any;
  errMsg                : any;
  customUrldata: any
  envType = environment?.envType || 'SDEX';
  appType = APPTYPES;

  protected readonly faEye = faEye;
  protected readonly faEyeSlash = faEyeSlash;
  protected readonly faSpinner = faSpinner;

  constructor(
    private fb: FormBuilder,
    private setupAccountService: SetupAccountService,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private storageService: StorageService
  ) { 
      this.storageService.clearLocalStorage();
   }

  ngOnInit(): void {
    this.welRegHeader = true;
    this.subRegHeader = true;
    if (environment.envType !== this.appType.sdex) {
      //getting url data
      this.authService.getOrgLoginData()?.subscribe({
        next: (response: any) => {
          this.customUrldata = response.data;
          this.changeFavicon(this.customUrldata?.favIcon)
        },error:(err)=>{

        }
      })
    }
  }

  validateOTP(event: any): void {
    const input = event.target.value;
    // Replace any non-numeric characters
    event.target.value = input.replace(/[^0-9]/g, '');
  }

  changeFavicon(newIconPath: string) {
    const favicon = this.document.getElementById('favicon') as HTMLLinkElement;
    if (favicon) {
        favicon.href = newIconPath;
    }
}

  secondaryForm: FormGroup = this.fb.group({
    emailAddress: ['', [Validators.required, Validators.pattern(RegExpPatterns.EmailPattern)]],
  })

  registerForm5: FormGroup = this.fb.group({
    otp: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.pattern(RegExpPatterns.PasswordPattern)]],
    repassword: ['', [Validators.required, this.passwordsMatchValidator]],
  });

  passwordsMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;
    if (password !== confirmPassword) {
      formGroup.get('confirmPassword')?.setErrors({ passwordsMismatch: true });
    } else {
      formGroup.get('confirmPassword')?.setErrors(null);
    }
  }

  verifySecondaryUser() {
    this.verifyLoader = true;
    this.setupAccountService.secondryUserAccountSetup(this.secondaryForm.value).subscribe({
      next: (res: any) => {
        this.secondaryUserEmail = this.secondaryForm.controls['emailAddress'].value
        this.showForm4 = false;
        this.showForm5 = true;
        setTimeout(() => {
          this.disabled = false
        }, 900000);
        this.verifyLoader = false;
      },
      error: (error) => {
        this.errorMsg = error?.error?.error?.message;
        this.verifyLoader = false;
      }
    });
  }

  resendOTP() {
    this.verifyLoader = true;
    this.setupAccountService.secondryUserAccountSetup({ emailAddress: this.secondaryUserEmail }).subscribe({
      next: (res: any) => {
        this.disabled = true
        setTimeout(() => {
          this.disabled = false
        }, 900000);
        this.verifyLoader = false;
      },
      error: (error) => {
        this.errMsg = error?.error?.error?.message;
        this.verifyLoader = false;
      }
    });
  }


  onVerify5() {
    this.verifyLoader = true;
    const payload = {
      otp: this.registerForm5.value.otp,
      password: CryptoJS.SHA256(this.registerForm5.value.repassword).toString(CryptoJS.enc.Hex),
      repassword: CryptoJS.SHA256(this.registerForm5.value.repassword).toString(CryptoJS.enc.Hex),
      confirmPassword: this.registerForm5.value.confirmPassword
    };
    delete payload.confirmPassword;
    this.setupAccountService.registerSecondryUser({ ...payload, emailAddress: this.secondaryUserEmail })?.subscribe({
      next: (res: any) => {
        this.showForm5 = false;
        this.showForm3 = true;
        this.verifyLoader = false;
      },
      error: (error) => {
        this.errMsg = error?.error?.error?.message;
        this.verifyLoader = false;
      }
    })
  }

  getInputValue(event: any) {
    if (this.errorMsg) {
      this.errorMsg = '';
    }
  }
}



