import {catchError, forkJoin, of} from "rxjs";
import {StorageKeys} from "./share/enums/storage.enum";

export function userSelfDetails(selfCall: any, blockChainCall: any, storageService: any, userSettingService: any, envType: any, callback: any){
  let forkJoinDetails: any = {
    selfDetails: selfCall.getSelfDetails().pipe(
      catchError(error => {
        console.error('Error fetching self details:', error);
        return of({ data: {} });
      })
    ),
    companyPrefs: userSettingService.getCompanyPrefs().pipe(
      catchError((error: any)=>{
        console.error('Error fetching company prefs:', error);
        return of({ data: {} });
      })
    )
  }

  if(envType === 'DAMS'){
    forkJoinDetails.documentVault = blockChainCall.getDocumentvaultStatus().pipe(
      catchError(error => {
        console.error('Error fetching document vault status:', error);
        return of({ data: {} });
      })
    );
  }else if(envType === 'SDEX'){
    forkJoinDetails.blockchain = blockChainCall.getBlockchainData().pipe(
      catchError(error => {
        console.error('Error fetching block chain data:', error);
        return of({ data: {} });
      })
    );
  }
  forkJoin(
    forkJoinDetails
  ).subscribe((res: any) => {
    if(!res?.selfDetails?.data){
      return callback(null);
    }

    let isAdmin: boolean = false;
    res?.selfDetails?.data?.assignedTo?.map((ele: any) => {
      isAdmin = ele.isAdmin;
    });
    if (res?.selfDetails?.data?.enrollmentType != 'Admin') {
      eSignBlockChainVerify(storageService, res?.selfDetails?.data, res.blockchain, res.companyPrefs?.data);
    } else if (res?.selfDetails?.data?.enrollmentType == 'Admin' && !res?.selfDetails?.data?.primary && !isAdmin) {  // set roles for super admin user
      checkPermissions(res, storageService);
    }
    storageService.setDataToLocalStorage(
      StorageKeys.SELF_DETAILS,
      res?.selfDetails?.data
    );
   let  selfDetail = {...res?.selfDetails?.data, documentVault: res.documentVault?.data}
    callback(selfDetail)
  })
}

 function checkPermissions(res: any, storageService: any){
  eSignBlockChainVerify(storageService, res?.selfDetails?.data, res.blockchain,res.companyPrefs?.data);
}

let hasSigned = false;
let hasESignPermission = false;
let hasGenerated = false;
let hasBlockChainPermission = false;
let assignedCompany: any = {};
 export function eSignBlockChainVerify(storageService: any, selfDetails: any = {}, blockchainDetails: any = {}, companyPrefs: any = {}) {
  let presentCompany = storageService.getDataFromLocalStorage('presentCompany');
  selfDetails.company = presentCompany || selfDetails?.assignedTo?.[0]?.company?._id;
  selfDetails.hideActionsNoEsign = false;
  selfDetails.hideActionsNoBlockchain = false;
  for(let assigned of (selfDetails?.assignedTo || [])){
    if ((assigned.company._id === selfDetails.company) && selfDetails.company) {
      assignedCompany = assigned;
      selfDetails.presentRoles = assigned.roles || [];
      selfDetails.primary = assigned.primary;
      selfDetails.isAdmin = assigned.isAdmin;
    }
  }
  checkAccess(selfDetails, blockchainDetails, companyPrefs);
  if(hasESignPermission && !hasSigned){
    selfDetails.hideActionsNoEsign = true;
  }
  if(hasBlockChainPermission && !hasGenerated){
    selfDetails.hideActionsNoBlockchain = true;
  }
  return selfDetails;
}

function checkAccess(selfDetails: any, blockchainDetails: any = {}, companyPrefs: any = {}) {
  const isESignEnabled = companyPrefs?.eSign?.enabled;
  const isBlockchainEnabled = companyPrefs?.package?.blockchain;

  hasESignPermission = isESignEnabled || false;
  hasSigned = hasESignPermission && !!selfDetails?.eSignSelfLink;

  hasBlockChainPermission = isBlockchainEnabled || false;
  hasGenerated = hasBlockChainPermission && blockchainDetails?.data?.keyGenerated;

  if ((hasESignPermission || hasBlockChainPermission) && (!assignedCompany.primary && !assignedCompany.isAdmin)) {
    hasBlockChainPermission = false;
    hasESignPermission = false;
    eSignBlockAccess(assignedCompany, selfDetails, blockchainDetails);
  }
}

export function eSignBlockAccess(assignedCompany: any, selfDetails: any, blockchainDetails: any = {}) {
  assignedCompany.roles?.forEach((x: any) => {
    if (x.permissions.filter((y: any) => y.module == 'e-sign-details').length > 0) {
      hasESignPermission = true;
      hasSigned = !!selfDetails?.eSignSelfLink;
    }
    for (let permission of x.permissions) {
      if (permission.module === 'package') {
        if (permission.enabled || permission.subPermissions.checker) {
          hasGenerated = blockchainDetails?.data?.keyGenerated;
          hasBlockChainPermission = true;
        }
      }
    }
  })
}

export function globalPreferences(commonService: any, module: string, fileType: any, callback: any){
  commonService.globalPreferences((err: any, preferences: any) => {
    if(module === 'fileExtensions' && preferences?.[module]){
      if(fileType === 'returnObject'){
        callback(null, preferences?.[module]);
        return;
      }
      let exts: any[] = [];
      for(let i =0; i < preferences[module].allowedFileTypes?.length; i++){
        if((fileType && preferences[module].allowedFileTypes[i].fileType === fileType) || (!fileType)){
          exts = exts.concat(preferences[module].allowedFileTypes[i].availableExtensions);
        }
      }
      callback(null, exts);
      return;
    }
    callback(null, preferences?.[module]);
  })
}
